import { defineComponent } from 'vue';
import CategoryTwelveRecords from '@/views/CategoryTwelve/CategoryTwelveRecords.vue';
export default defineComponent({
    name: 'CategoryTwelveLayout',
    components: { CategoryTwelveRecords },
    data() {
        return {
            loading: false,
            tab: 'Cat. 12 - EOL Treatment of Sold Products Records'
        };
    }
});
